import client from "ApiClient";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Card, CircularProgress, Dialog, DialogActions, DialogContent } from "@mui/material";
import MUIDataTable from "mui-datatables";
import MDInput from "components/MDInput";
import { Chip } from "@material-ui/core";
import DataTable from "examples/Tables/DataTable";
import DefaultCell from "layouts/Domains/components/DefaultCell";

/* eslint-disable */
export default function Funnels() {
  const [isLoading, setIsLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);

  const navigate = useNavigate();

  const options = {
    method: "GET",
    url: "funnels",
  };

  const handleSave = () => {
    const options = {
      method: "POST",
      url: `funnels`,
      headers: {
        "content-type": "application/json",
      },
    };

    options.data = JSON.stringify({
      fun_name: name,
      fun_description: description,
      fun_url: "",
      fun_slug: "",
      fun_status: 3,
      fun_json: "",
    });

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setOpenAdd(false);
        navigate(`/funnels/add?id=${response.fun_id}`);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getData = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `funnels`;
    await client
      .request(options)
      .then((response) => {        
        setIsLoading(false);
        setItems(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const dataTableData = {
    columns: [
      {
        Header: "status",
        accessor: "fun_status",
        Cell: ({ value }) => {
          let status;
          switch (value) {
            case 0:
              return (
                <Chip
                  label="Published"
                  color="success"
                  size="small"
                  style={{ backgroundColor: "#4caf50", color: "#fff" }}
                />
              );
            case 3:
              return (
                <Chip
                  label="Draft"
                  color="info"
                  size="small"
                  style={{ backgroundColor: "#0088F5", color: "#fff" }}
                />
              );
          }
        },
      },
      {
        Header: "Name",
        accessor: "fun_name",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Description",
        accessor: "fun_description",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "URL",
        accessor: "fun_url",
        Cell: ({ value }) => (
          <DefaultCell
            value={
              <a href={value} target="_blank">
                {value}
              </a>
            }
          />
        ),
      },
      {
        Header: "Slug",
        accessor: "fun_slug",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "creation date",
        accessor: "fun_creationDate",
        Cell: ({ value }) => moment(value).format("DD/MM/YYYY"),
      },
    ],

    rows: items,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog open={openAdd}>
        <DialogContent>
          <MDBox>
            <MDInput
              style={{ padding: 10 }}
              type="text"
              label="Funnel name"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </MDBox>
          <MDBox>
            <MDInput
              style={{ padding: 10 }}
              type="text"
              label="Description"
              fullWidth
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </MDBox>
        </DialogContent>
        <DialogActions style={{ color: "white", backgroundColor: "white" }}>
          <MDButton
            onClick={() => setOpenAdd(false)}
            disabled={isLoading}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </MDButton>
          <MDButton onClick={handleSave} disabled={isLoading} variant="gradient" color="success">
            Save
          </MDButton>
          {isLoading && (
            <CircularProgress size={24} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          )}
        </DialogActions>
      </Dialog>
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="primary" onClick={() => setOpenAdd(true)}>
            new funnel
          </MDButton>
        </MDBox>
        <Card>
          <DataTable table={dataTableData} entriesPerPage={true} canSearch />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
