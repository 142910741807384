import client from "ApiClient";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import moment from "moment";
import DataTable from "examples/Tables/DataTable";
import DefaultCell from "./components/DefaultCell";
import { Card } from "@mui/material";

/* eslint-disable */
export default function Domains() {
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);

  const navigate = useNavigate();

  const options = {
    method: "GET",
    url: "domains",
  };

  const getData = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `domains`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setItems(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      label: "DOMAIN",
      name: "dom_domain",
    },
    {
      label: "CREATION DATE",
      name: "dom_creationDate",
      options: {
        setCellHeaderProps: () => {
          return { align: "center" };
        },
        customBodyRender: (value) => moment(value).format("DD/MM/YYYY"),
      },
    },
    {
      label: "STATUS",
      name: "dom_status",
      options: {
        customBodyRender: (value) => {
          let status;
          if (value === 1) {
            status = <StatusCell icon="done" color="success" status="Success" />;
          } else {
            status = <StatusCell icon="close" color="error" status="Fail" />;
          }

          return status;
        },
      },
    },
  ];

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "You don't have any domain",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "domain/s selected",
      },
    },
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "simple",
    selectableRows: "single",
    fixedHeader: false,
    fixedSelectColumn: false,
    searchPlaceholder: "Search",
    downloadOptions: { filename: "domains.csv", separator: "," },
    onRowSelectionChange: (curRowSelected, allRowsSelected, rowsSelected) => {
      setSelectedItem(rowsSelected);
    },
  };

  const dataTableData = {
    columns: [
      {
        Header: "status",
        accessor: "dom_status",
        Cell: ({ value }) => {
          let status;
          if (value === 0) {
            status = <StatusCell icon="done" color="success" status="Valid" />;
          } else {
            status = <StatusCell icon="close" color="error" status="Fail" />;
          }
          return status;
        },
      },
      {
        Header: "domain",
        accessor: "dom_domain",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "creation date",
        accessor: "dom_creationDate",
        Cell: ({ value }) => moment(value).format("DD/MM/YYYY"),
      },
    ],

    rows: items,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="primary" onClick={() => navigate("/domains/add")}>
            new domain
          </MDButton>
          {/*}
          <MDBox display="flex">
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
          </MDBox>
          */}
        </MDBox>
        <Card>
          <DataTable table={dataTableData} entriesPerPage={true} canSearch />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
