/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import howImage from "assets/images/1.d89cc93d7fbd4ab64d54.gif";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Table, TableBody, TableCell, TableRow, Checkbox } from "@mui/material";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDInput from "components/MDInput";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Icon,
  InputAdornment,
} from "@material-ui/core";
import client from "ApiClient";
import SimpleReactValidator from "simple-react-validator";
import isValidDomain from "is-valid-domain";
import { json } from "react-router-dom";

function getSteps() {
  return ["DNS", "Domain", "Verify", "Finish"];
}

function AddDomain() {
  const [domain, setDomain] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [isVerifing, setIsVerifing] = useState(false);
  const [verified, setVerified] = useState(true);
  const [showMe, setShowMe] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const navigate = useNavigate();

  const simpleValidator = useRef(
    new SimpleReactValidator({
      validators: {
        domain: {
          // name the rule
          message: "Please entera valid domain",
          rule: (val, params, validator) => {
            return isValidDomain(val);
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
          required: true, // optional
        },
      },
    })
  );

  //  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  const handleNext = () => {
    switch (activeStep) {
      case 0:
        setActiveStep(activeStep + 1);
        break;
      case 1:
        setActiveStep(activeStep + 1);
        break;
      case 2:
        setActiveStep(activeStep + 1);
        break;
      case 3:
        navigate("/domains");
        break;
    }
  };

  useEffect(() => {
    if (activeStep == 2) verify();
    if (activeStep == 3) save();
  }, [activeStep]);

  const options = {
    method: "GET",
    url: "domains",
    headers: {
      "content-type": "application/json",
    },
  };

  const verify = async () => {
    setIsVerifing(true);
    options.method = "GET";
    options.url = `domains/verify?domain=${domain}`;
    await client
      .request(options)
      .then((response) => {
        setIsVerifing(false);
        if (response === "ok") setVerified(true);
        else setVerified(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const save = async () => {
    setIsVerifing(true);
    options.method = "POST";
    options.url = `domains`;
    options.data = JSON.stringify({
      dom_status: 1,
      dom_user_id: 3,
      dom_domain: domain,
    });
    await client
      .request(options)
      .then((response) => {
        setIsVerifing(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <MDBox>
            <MDBox mx="auto" my={4}>
              <MDBox mb={1}>
                <MDTypography variant="h5" fontWeight="regular">
                  Change your DNS records in your third party domain provider
                </MDTypography>
              </MDBox>
              <MDTypography variant="body2" color="text">
                On your domain provider’s website, first log into your account, then locate the DNS
                settings or domain management area and change the following records. Once done,
                please check the "confirmed" box below.
              </MDTypography>
              <FormControlLabel
                style={{ marginTop: "1rem", marginBottom: "1rem" }}
                control={<Checkbox checked={confirmed} onChange={() => setConfirmed(!confirmed)} />}
                label={
                  <MDTypography variant="h6" fontWeight="medium">
                    Confirmed
                  </MDTypography>
                }
              />
              <MDBox display="flex" justifyContent="center">
                <MDTypography variant="body2" color="text">
                  Update the A record in your third party domain provider OR create a new A record
                  if there is no existing one with the following values.
                </MDTypography>
                <MDButton onClick={() => setShowMe(true)}>
                  <MDTypography variant="body2" color="info">
                    Show me how!
                  </MDTypography>
                </MDButton>
              </MDBox>
            </MDBox>
            <MDBox mt={2}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <MDTypography variant="body2" fontWeight="medium" textAlign="center">
                        Type
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" fontWeight="medium" textAlign="center">
                        Name / Host Name
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" fontWeight="medium" textAlign="center">
                        IP Address / Value
                      </MDTypography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <MDTypography variant="body2" color="text" textAlign="center">
                        A
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" color="text" textAlign="center">
                        @
                      </MDTypography>
                    </TableCell>
                    <TableCell>
                      <MDTypography variant="body2" color="text" textAlign="center">
                        35.193.150.156
                      </MDTypography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </MDBox>
          </MDBox>
        );
      case 1:
        return (
          <MDBox>
            <MDBox mx="auto" my={4}>
              <MDBox mb={1}>
                <MDTypography variant="h5" fontWeight="regular">
                  Enter your domain
                </MDTypography>
                <MDBox color="red">
                  {simpleValidator.current.message("Domain", domain, "domain")}
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox mt={2}>
              <MDInput
                autoComplete="domain"
                type={"text"}
                label="Domain"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <Icon>language</Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>
          </MDBox>
        );
      case 2:
        return isVerifing ? (
          <MDBox textAlign="center" justifyContent="center" mt="2">
            <CircularProgress size={60} style={{ marginLeft: 15, position: "relative", top: 4 }} />
            <MDTypography variant="h5" color text>
              Verifing domain
            </MDTypography>
          </MDBox>
        ) : verified ? (
          <MDBox textAlign="center" justifyContent="center" mt="2">
            <CheckCircleOutlineOutlinedIcon color="success" fontSize="large" />
            <MDTypography variant="h5" color="success">
              Domain verification successfuly
            </MDTypography>
          </MDBox>
        ) : (
          <MDBox textAlign="center" justifyContent="center" mt="2">
            <HighlightOffIcon color="error" fontSize="large" />
            <MDTypography variant="h5" color="error">
              Domain verification failed
            </MDTypography>
          </MDBox>
        );
      case 3:
        return isVerifing ? (
          <MDBox textAlign="center" justifyContent="center" mt="2">
            <CircularProgress size={60} style={{ marginLeft: 15, position: "relative", top: 4 }} />
            <MDTypography variant="h5" color text>
              Creating domain
            </MDTypography>
          </MDBox>
        ) : (
          <MDBox textAlign="center" justifyContent="center" mt="2">
            <CheckCircleOutlineOutlinedIcon color="success" fontSize="large" />
            <MDTypography variant="h5" color="success">
              The domain has been created successfylly
            </MDTypography>
          </MDBox>
        );
      default:
        return null;
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog open={showMe} fullWidth maxWidth="lg">
        <DialogContent>
          <img src={howImage} alt="how" />
        </DialogContent>
        <DialogActions>
          <MDButton onClick={() => setShowMe(false)}>Close</MDButton>
        </DialogActions>
      </Dialog>
      <MDBox pt={3} pb={8}>
        <Grid container justifyContent="center" sx={{ my: 4 }}>
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Add a new domain
                </MDTypography>
              </MDBox>
            </MDBox>
            <Card>
              <MDBox mt={-3} mx={2}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                <MDBox>
                  {getStepContent(activeStep)}
                  <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton variant="outlined" color="dark" onClick={handleBack}>
                        back
                      </MDButton>
                    )}
                    <MDButton
                      disabled={!confirmed || (activeStep === 1 && !isValidDomain(domain))}
                      variant={isLastStep ? (verified ? "gradient" : "outlined") : "gradient"}
                      color={isLastStep ? (verified ? "success" : "error") : "success"}
                      onClick={handleNext}
                    >
                      {isLastStep
                        ? verified
                          ? "close"
                          : "cancel"
                        : activeStep === 2
                        ? "save"
                        : "next"}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddDomain;
