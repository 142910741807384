/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";

import EmailEditor from "react-email-editor";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Material Dashboard 2 PRO React examples
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import MDInput from "components/MDInput";
import client from "ApiClient";
import { Card, Chip, CircularProgress } from "@material-ui/core";
import { Autocomplete, Dialog } from "@mui/material";

function Page(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(null);
  const [loadingText, setLoadingText] = useState("");
  const [forms, setForms] = useState([]);
  const emailEditorRef = useRef(null);
  const [pageTitle, setPageTitle] = useState(props.page ? props.page.pag_title : "");

  const options = {
    method: "GET",
    url: "pages",
    headers: {
      "content-type": "application/json",
    },
  };

  const save = () => {
    setIsLoading(true);
    setLoadingText("Saving...");
    options.method = page == null ? "POST" : "PUT";
    options.url = `pages`;
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml((data) => {
      const html = data.html;
      const design = data.design;

      options.data = JSON.stringify({
        pag_fun_id: props.funnel.fun_id,
        pag_status: page == null ? 0 : page.pag_status,
        pag_user_id: page == null ? 0 : page.pag_user_id,
        pag_url: page == null ? "" : page.pag_url,
        pag_html: html,
        pag_json: JSON.stringify(design),
        pag_title: pageTitle,
        pag_version: 2,
        pag_slug: "",
      });
      if (page != null)
        options.data = JSON.stringify({
          pag_id: page.pag_id,
          pag_creationDate: page.pag_creationDate,
          ...JSON.parse(options.data),
        });
      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          setPageTitle("");
          props.onDone(response);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const getFroms = async () => {
    options.method = "GET";
    options.url = `forms`;
    await client
      .request(options)
      .then((response) => {
        let form = [];

        response.map((item) => {
          form.push({
            label: item.name,
            value: item.html,
          });
        });
        setForms(form);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onReady = (unlayer) => {
    unlayer.setBodyValues({
      contentWidth: "900px", // or percent "50%"
    });

    unlayer.setLinkTypes([
      {
        name: "static_next_page",
        label: "Go to next page",
        attrs: {
          href: "[NEXT_PAGE]",
          target: "_self",
        },
      },
    ]);

    // unlayer.reloadProvider("blocks");

    // const id = searchParams.get("id");

    if (props.page) {
      options.method = "GET";
      options.url = `pages`;
      client
        .request(options)
        .then((response) => {
          //  console.log(response.filter((p) => p.pag_id == id)[0]);
          setPage(response.filter((p) => p.pag_id == props.page.pag_id)[0]);
          emailEditorRef.current?.editor.loadDesign(
            JSON.parse(response.filter((p) => p.pag_id == props.page.pag_id)[0].pag_json)
          );
          setPageTitle(props.page.pag_title);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getFroms();
  }, []);
  /*
  useEffect(() => {
   
  }, [props]);
*/
  return (
    <Dialog
      open={props.openModal}
      onClose={(e, reason) => clearVariables(reason)}
      fullScreen
      disableEscapeKeyDown
    >
      <Card style={{ padding: 10, height: "100%", backgroundColor: "#00044d" }}>
        <Grid container>
          <Grid item xs={12} md={6} marginBottom={2}>
            <MDBox display="flex" alignItems="flex-start">
              <MDButton
                disabled={isLoading}
                variant="outlined"
                color="withe"
                onClick={() => props.closeModal()}
                size="small"
                style={{
                  marginRight: 10,
                }}
              >
                close
              </MDButton>
              <MDButton
                disabled={isLoading}
                variant="gradient"
                color="success"
                onClick={() => save()}
                size="small"
                style={{
                  marginRight: 10,
                }}
              >
                save
              </MDButton>
              <MDInput
                size="small"
                variant="outlined"
                disabled={isLoading}
                label="Page title"
                value={pageTitle}
                onChange={(e) => setPageTitle(e.target.value)}
                style={{ width: 300, marginRight: 20, backgroundColor: "#FFF" }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={3} style={{ width: "100%", height: "100%" }}>
            {isLoading && (
              <MDBox display="flex">
                <MDTypography color="white">{loadingText}</MDTypography>
                <CircularProgress
                  size={30}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                  color="info"
                />
              </MDBox>
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent="center" sx={{ my: 1 }}>
          <Grid item xs={12} lg={12} style={{ width: "100%", height: "100%" }}>
            <EmailEditor
              ref={emailEditorRef}
              onReady={onReady}
              projectId="205839"
              minHeight={800}
              displayMode="web"
              appearance={{
                theme: "modern_dark",
                panels: {
                  tools: {
                    dock: "left",
                  },
                },
                features: {
                  preview: true,
                },
              }}
              options={{
                customCSS: [
                  window.location.protocol + "//" + window.location.host + "/customTool.css",
                ],
                customJS: [
                  window.location.protocol + "//" + window.location.host + "/customTool.js",
                ],
                editor: {
                  autoSelectOnDrop: true,
                },
                tools: {
                  // my_tool is the name of our custom tool
                  // It is required to add custom# before the name of your custom tool
                  "custom#forms_tool": {
                    properties: {
                      form: {
                        editor: {
                          data: {
                            forms: forms,
                          },
                        },
                      },
                    },
                  },
                  form: {
                    enabled: false,
                    properties: {
                      action: {
                        editor: {
                          data: {
                            allowCustomUrl: true,
                            actions: [
                              {
                                label: "Next page",
                                method: "GET",
                                url: "[NEXT_PAGE]",
                              },
                            ],
                          },
                        },
                      },
                    },
                  },
                  video: {
                    enabled: true,
                  },
                  timer: {
                    properties: {
                      width: {
                        value: {
                          autoWidth: false,
                          width: "50%",
                        },
                      },
                    },
                  },
                  social: {
                    enabled: true,
                  },
                },
                translations: {
                  "en-US": {
                    "tools.tabs.content": "Widgets",
                    "tools.tabs.blocks": "Sections",
                  },
                },
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </Dialog>
  );
}

export default Page;
