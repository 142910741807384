import { Chip, Divider, Icon, withStyles } from "@material-ui/core";
import StyleIcon from "@mui/icons-material/Style";
import { Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Handle, Position } from "reactflow";

/*eslint-disable*/
export default function FirstNode(node) {
  /*
    public const int AUTOMATION_START_EVENT_SUBSCRIBER_ADDED = 0;
        public const int AUTOMATION_START_EVENT_FINISHED_SERIE = 1;
        public const int AUTOMATION_START_EVENT_ENGAGED_BROADCAST = 2;
        public const int AUTOMATION_START_EVENT_ENGAGED_CATALOG = 3;
        public const int AUTOMATION_START_EVENT_ENGAGED_ITEM = 4;

        public const string AUTOMATION_STEP_TYPE_INPUT = "AUTOMATION_STEP_TYPE_INPUT";
        public const string AUTOMATION_STEP_TYPE_SEND_EMAIL = "AUTOMATION_STEP_TYPE_SEND_EMAIL";
        public const string AUTOMATION_STEP_TYPE_CALL_WEBHOOK = "AUTOMATION_STEP_TYPE_CALL_WEBHOOK";
        public const string AUTOMATION_STEP_TYPE_WAIT_TIME = "AUTOMATION_STEP_TYPE_WAIT_TIME";
        public const string AUTOMATION_STEP_TYPE_WAIT_EVENT = "AUTOMATION_STEP_TYPE_WAIT_EVENT";
        */

  const StyleChip = withStyles({
    root: {
      backgroundColor: "gray",
      borderColor: "white",
      "& .MuiChip-label": {
        fontSize: 8,
        color: "white",
      },
    },
  })(Chip);

  const onClick = (event) => {
    node.data.onClickAdd(event, node);
  };

  const getIcon = (type) => {
    switch (type) {
      case "FUNNELITEM_SPLITTEST":
        return (
          <Icon fontSize="small" mt={3} rotate={180}>
            call_split
          </Icon>
        );
      case "FUNNELITEM_PAGE":
        return (
          <Icon fontSize="small" mt={3}>
            web
          </Icon>
        );
      case "FUNNELITEM_CALLURL":
        return (
          <Icon fontSize="small" mt={3}>
            webhook
          </Icon>
        );
      case "FUNNELITEM_EMAIL":
        return (
          <Icon fontSize="small" mt={3}>
            mail
          </Icon>
        );
      case "FUNNELITEM_ADDSUBSCRIBER":
        return (
          <Icon fontSize="small" mt={3}>
            person_add
          </Icon>
        );
      default:
        return "error";
    }
  };

  const getEvent = (type) => {
    switch (type) {
      case "FUNNELITEM_SPLITTEST":
        return "Split test";
      case "FUNNELITEM_PAGE":
        return "Page";
      default:
        return "error";
    }
  };

  const getLabel = (type) => {
    switch (type) {
      case "FUNNELITEM_SPLITTEST":
        return "tests";
      case "FUNNELITEM_PAGE":
        return "";
      default:
        return "";
    }
  };

  const getData = (type) => {
    switch (type) {
      case "FUNNELITEM_SPLITTEST":
        return (
          <MDBox>
            {node.data.fus_additionalData.split(",").map((tag) => {
              return <StyleChip variant="outlined" label={tag.trim()} size="small" />;
            })}
          </MDBox>
        );
      case "FUNNELITEM_PAGE":
        return (
          <MDBox>
            <MDTypography variant="outlined">{node.data.fus_additionalData}</MDTypography>
          </MDBox>
        );

      default:
        return "";
    }
  };

  return (
    <div
      style={{
        border: "1px solid #eee",
        padding: "5px",
        borderRadius: "5px",
        background: node.selected ? "#CCCCCC" : "#00CCFF22",
      }}
    >
      <MDBox display="flex">
        {getIcon(node.data.fus_type)}
        <MDBox display="flex" style={{ width: "100%" }}>
          <MDTypography variant="caption" fontWeight="medium" ml={1} style={{ width: "150px" }}>
            {getEvent(node.data.fus_type)}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="right" style={{ width: "100%" }}>
          {node.selected && (
            <Tooltip title="delete event" placement="top">
              <Icon
                color="action"
                style={{ fontSize: "15px" }}
                onClick={(e) => node.data.onDelete(e, node)}
              >
                delete
              </Icon>
            </Tooltip>
          )}
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox display="flex">
        <MDTypography ml={1} variant="caption" fontWeight="medium">
          {getLabel(node.data.fus_type)}
        </MDTypography>
      </MDBox>
      {getData(node.data.fus_type)}

      <Handle type="source" position={Position.Bottom}>
        {node.selected && (
          <Tooltip title="Add a new item" placement="top">
            <Icon color="action" fontSize="small" onClick={onClick} style={{ marginLeft: -8 }}>
              add_circle
            </Icon>
          </Tooltip>
        )}
      </Handle>
    </div>
  );
}
