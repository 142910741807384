/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";

import EmailEditor from "react-email-editor";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDInput from "components/MDInput";
import client from "ApiClient";
import { Card, Chip, CircularProgress } from "@material-ui/core";
import { Autocomplete, Dialog } from "@mui/material";

function AddPages() {
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(null);
  const [loadingText, setLoadingText] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [domains, setDomains] = useState([]);
  const [forms, setForms] = useState([]);
  const [pagSlug, setPagSlug] = useState("");
  const [selectedDomain, setSelectedDomain] = useState(process.env.REACT_APP_MAIN_DOMAIN);
  const navigate = useNavigate();
  const emailEditorRef = useRef(null);
  const [searchParams] = useSearchParams();

  const options = {
    method: "GET",
    url: "pages",
    headers: {
      "content-type": "application/json",
    },
  };

  const save = () => {
    setIsLoading(true);
    setLoadingText("Saving...");
    options.method = page == null ? "POST" : "PUT";
    options.url = `pages`;
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml((data) => {
      const html = data.html;
      const design = data.design;

      options.data = JSON.stringify({
        pag_status: page == null ? 3 : page.pag_status,
        pag_user_id: page == null ? 0 : page.pag_user_id,
        pag_url: page == null ? "" : page.pag_url,
        pag_html: html,
        pag_json: JSON.stringify(design),
        pag_title: pageTitle,
        pag_version: 2,
        pag_slug: pagSlug,
      });
      if (page != null)
        options.data = JSON.stringify({
          pag_id: page.pag_id,
          pag_creationDate: page.pag_creationDate,
          ...JSON.parse(options.data),
        });
      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          setPage(response);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const unpublish = () => {
    setIsLoading(true);
    setLoadingText("Saving...");
    options.method = "PUT";
    options.url = `pages`;
    const unlayer = emailEditorRef.current?.editor;

    unlayer?.exportHtml((data) => {
      const html = data.html;
      const design = data.design;

      options.data = JSON.stringify({
        pag_status: 3,
        pag_user_id: page == null ? 0 : page.pag_user_id,
        pag_url: "",
        pag_html: html,
        pag_json: JSON.stringify(design),
        pag_title: pageTitle,
        pag_version: 2,
        pag_slug: pagSlug,
      });
      if (page != null)
        options.data = JSON.stringify({
          pag_id: page.pag_id,
          pag_creationDate: page.pag_creationDate,
          ...JSON.parse(options.data),
        });
      client
        .request(options)
        .then((response) => {
          setIsLoading(false);
          setPage(response);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const publish = () => {
    setIsLoading(true);
    setLoadingText("Publishing...");
    options.method = page == null ? "POST" : "PUT";
    options.url = `pages`;
    const unlayer = emailEditorRef.current?.editor;
    const userName = localStorage.getItem("userName").toLowerCase().replace(" ", "-");
    unlayer?.exportHtml((data) => {
      const html = data.html;
      const design = data.design;
      options.data = JSON.stringify({
        pag_status: 0,
        pag_user_id: page == null ? 0 : page.pag_user_id,
        pag_url:
          selectedDomain == process.env.REACT_APP_MAIN_DOMAIN
            ? `https://${userName}.${process.env.REACT_APP_MAIN_DOMAIN}/${pagSlug}`
            : "https://" + selectedDomain,
        pag_html: html,
        pag_json: JSON.stringify(design),
        pag_title: pageTitle,
        pag_version: 2,
        pag_slug: pagSlug,
      });

      if (page != null)
        options.data = JSON.stringify({
          pag_id: page.pag_id,
          pag_creationDate: page.pag_creationDate,
          ...JSON.parse(options.data),
        });
      
      client
        .request(options)
        .then((response) => {
          setPage(response);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const getDomains = async () => {
    options.method = "GET";
    options.url = `domains`;
    await client
      .request(options)
      .then((response) => {
        const domains = response.map((d) => d.dom_domain);
        domains.push(process.env.REACT_APP_MAIN_DOMAIN);
        setDomains(domains);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFroms = async () => {
    options.method = "GET";
    options.url = `forms`;
    await client
      .request(options)
      .then((response) => {
        let form = [];

        response.map((item) => {
          form.push({
            label: item.name,
            value: item.html,
          });
        });
        setForms(form);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onReady = (unlayer) => {
    unlayer.setBodyValues({
      contentWidth: "900px", // or percent "50%"
    });

    // unlayer.reloadProvider("blocks");

    const id = searchParams.get("id");

    if (id) {
      options.method = "GET";
      options.url = `pages`;
      client
        .request(options)
        .then((response) => {
          //  console.log(response.filter((p) => p.pag_id == id)[0]);
          setPage(response.filter((p) => p.pag_id == id)[0]);
          emailEditorRef.current?.editor.loadDesign(
            JSON.parse(response.filter((p) => p.pag_id == id)[0].pag_json)
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getFroms();
    getDomains();
  }, []);

  const changePageSlug = (event) => {
    const slug = event.target.value;
    const regex = new RegExp("^[a-zA-Z_]+$");

    if (regex.test(slug)) setPagSlug(slug);
    else if (slug == "") setPagSlug("");
    else setPagSlug(pagSlug);
  };

  useEffect(() => {
    if (page) {
      setPageTitle(page.pag_title);
      setPagSlug(page.pag_slug);
    }
  }, [page]);

  const getStatus = (status) => {
    switch (status) {
      case 0:
        return (
          <Chip
            label="Published"
            color="success"
            size="small"
            style={{ backgroundColor: "#4caf50", color: "#fff" }}
          />
        );
      case 3:
        return (
          <Chip
            label="Draft"
            color="info"
            size="small"
            style={{ backgroundColor: "#0088F5", color: "#fff" }}
          />
        );
    }
  };

  return (
    <Dialog
      open={true}
      onClose={(e, reason) => clearVariables(reason)}
      fullScreen
      disableEscapeKeyDown
    >
      <Card style={{ padding: 10, height: "100%", backgroundColor: "#00044d" }}>
        <Grid container>
          <Grid item xs={12} md={6} marginBottom={2}>
            <MDBox display="flex" alignItems="flex-start">
              <MDButton
                disabled={isLoading}
                variant="outlined"
                color="withe"
                onClick={() => navigate("/pages")}
                size="small"
                style={{
                  marginRight: 10,
                }}
              >
                close
              </MDButton>
              <MDButton
                disabled={isLoading}
                variant="gradient"
                color="success"
                onClick={() => save()}
                size="small"
                style={{
                  marginRight: 10,
                }}
              >
                save
              </MDButton>
              {page && page.pag_status == 0 && (
                <MDButton
                  disabled={isLoading}
                  variant="gradient"
                  color="success"
                  onClick={() => unpublish()}
                  size="small"
                >
                  unpublish
                </MDButton>
              )}
              <MDButton
                disabled={isLoading}
                variant="gradient"
                color="success"
                onClick={() => publish()}
                style={{ marginLeft: 10 }}
                size="small"
              >
                publish in domain
              </MDButton>
              <Autocomplete
                autofillBackgroundColor="highlight"
                disabled={isLoading}
                variant="outlined"
                defaultValue={process.env.REACT_APP_MAIN_DOMAIN}
                options={domains}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
                style={{ marginLeft: 10, marginRight: 50, width: 200, backgroundColor: "#FFF" }}
                onChange={(e, value) => setSelectedDomain(value)}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={3} style={{ width: "100%", height: "100%" }}>
            {isLoading && (
              <MDBox display="flex">
                <MDTypography color="white">{loadingText}</MDTypography>
                <CircularProgress
                  size={30}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                  color="info"
                />
              </MDBox>
            )}
          </Grid>
          {page && (
            <Grid item xs={12} md={3} style={{ width: "100%", height: "100%", textAlign: "right" }}>
              <MDBox display="block">{getStatus(page.pag_status)}</MDBox>
              <MDBox display="block">
                <MDTypography
                  component={Link}
                  to={page.pag_url}
                  target="_blank"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  {page.pag_url}
                </MDTypography>
              </MDBox>
            </Grid>
          )}

          <Grid item xs={12} style={{ width: "100%", height: "100%", textAlign: "right" }}>
            <MDBox display="flex" alignItems="flex-start">
              <MDInput
                size="small"
                variant="outlined"
                disabled={isLoading}
                label="Page title"
                value={pageTitle}
                onChange={(e) => setPageTitle(e.target.value)}
                style={{ width: 300, marginRight: 20, backgroundColor: "#FFF" }}
              />
              <MDInput
                size="small"
                variant="outlined"
                disabled={isLoading}
                label="Page Slug"
                placeholder="Only letters and underscores"
                value={pagSlug}
                // onKeyPress={(e) => changePageSlug(e)}
                onChange={(e) => changePageSlug(e)}
                style={{ width: 300, backgroundColor: "#FFF" }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" sx={{ my: 1 }}>
          <Grid item xs={12} lg={12} style={{ width: "100%", height: "100%" }}>
            <EmailEditor
              ref={emailEditorRef}
              onReady={onReady}
              projectId="205839"
              minHeight={800}
              displayMode="web"
              appearance={{
                theme: "modern_dark",
                panels: {
                  tools: {
                    dock: "left",
                  },
                },
                features: {
                  preview: true,
                },
              }}
              options={{
                customCSS: [
                  window.location.protocol + "//" + window.location.host + "/customTool.css",
                ],
                customJS: [
                  window.location.protocol + "//" + window.location.host + "/customTool.js",
                ],
                editor: {
                  autoSelectOnDrop: true,
                },
                tools: {
                  // my_tool is the name of our custom tool
                  // It is required to add custom# before the name of your custom tool
                  "custom#forms_tool": {
                    properties: {
                      form: {
                        editor: {
                          data: {
                            forms: forms,
                          },
                        },
                      },
                    },
                  },
                  form: {
                    enabled: true,
                    properties: {
                      action: {
                        editor: {
                          data: {
                            allowCustomUrl: true,
                            actions: [
                              {
                                label: "Next page",
                                method: "POST",
                                url: "http://whatever.com/marketing-form-submission",
                              },                             
                            ],
                          },
                        },
                      },
                    },
                  },
                  video: {
                    enabled: true,
                  },
                  timer: {
                    properties: {
                      width: {
                        value: {
                          autoWidth: false,
                          width: "50%",
                        },
                      },
                    },
                  },
                  social: {
                    enabled: true,
                  },
                },
                translations: {
                  "en-US": {
                    "tools.tabs.content": "Widgets",
                    "tools.tabs.blocks": "Sections",
                  },
                },
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </Dialog>
  );
}

export default AddPages;
