import { useState } from "react";
import gjspresetwebpage from "grapesjs-preset-webpage";
import gjsblockbasic from "grapesjs-blocks-basic";
import grapesjs from "grapesjs";
import GjsEditor from "@grapesjs/react";
import client from "ApiClient";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

/* eslint-disable */
export default function Grape() {
  const [grapeEditor, setGrapeEditor] = useState(null);
  /*  var editor = grapesjs.init({
    container: "#gjs",
    plugins: [gjspresetwebpage, gjsblockbasic],
  });*/

  const options = {
    method: "GET",
    url: "pages",
    headers: {
      "content-type": "application/json",
    },
  };

  const getCode = () => {
    var data = grapeEditor.getProjectData();
    console.log(data);

    options.method = "POST";
    options.url = `pages`;

    options.data = JSON.stringify({
      pag_status: 1,
      pag_user_id: 3,
      pag_url: "https://damian.funnelsense.ai/test 1",
      pag_html: `<body><meta charset="UTF-8"/><meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"/><meta http-equiv="X-UA-Compatible" content="ie=edge"/><link rel="icon" href="https://kierans-demo.floatfunnels.com/images/favicon.ico"/><title>Damian second test</title><link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css" id="ii86"/><div id="ic9l"><div id="ia5j" class="container"><div id="ium5" class="row"><div id="iqsv" class="cell"><img id="i0oh" src="https://media.floatfunnels.com/image.php?page=kierans-demo/42492&imgfile=5cf50173-3658-a549-9810-816bab38761b.png"/><div id="ixab5"><p id="ipzb">
           </p><p id="irnu5"></p><p>FREE 20 PAGE BOOK </p><p>REVEALS 7 <br id="i9l5q" draggable="true"/>INCREDIBLE HEALTH <br id="i54iy" draggable="true"/>BENEFITS OF THE <br id="inyn2" draggable="true"/>KETO DIET.</p><p></p><p id="is214"></p></div><div id="ichf"><p data-mce-style="text-align: center;" id="i8mhr"></p><p data-mce-style="text-align: left;" id="ia3wz">Enter your best email address in the form below to get free instant access to the book...</p><p></p></div><form id="i5l7h"><div id="i2hjg" class="form-group"><input type="email" placeholder="Enter your email address" required class="input"/></div><div id="imn1y"><span>Insert here your custom code</span></div><div id="ivywj" class="form-group"><button type="submit" class="button">CLICK HERE TO DOWNLOAD</button></div></form></div><div id="inui" class="cell"><img id="iydry" src="https://media.floatfunnels.com/image.php?page=kierans-demo/42492&imgfile=ff38d7b1-3676-756b-cd98-3591204f0a73.png"/></div></div></div></div><div id="iy61g"><div id="ig0xu" class="container"><div id="i85z"><p id="ii8f4">
       </p><p id="i58bk"></p><p>IN THE BOOK YOU'LL DISCOVER: <br id="ii2cg" draggable="true"/></p><p></p><div><div id="is124"></div></div><p id="iovrf"></p><div id="iz4eh"><div id="iwh5b">
         </div></div></div><div id="ift9h" class="row"><div id="il6egef" class="innercol"><i id="ippm8" class="fas fa-bullseye"><p id="ion72"><br data-mce-bogus="1" id="isg44"/></p></i><h3 id="isarn"><p>7 BENEFITS OF THE KETO DIET.</p></h3><span id="i96zv">From setting goals to time-blocking, this planner will help you be more intentional with your workouts. You’ll feel confident, committed—and stronger.</span></div><div id="is6fz"><div id="izx3z">
         </div></div><div id="i9tjkdf" class="innercol"><i id="iqu1z" class="fas fa-list-check"><p id="iuws6"><br data-mce-bogus="1" id="iw0ad"/></p></i><h3 id="ioz4ej"><p>THE 3 BIGGEST KETO MISTAKES.</p></h3><span id="ixbyh">Keeping a record of each workout and your accomplishments is the easiest way to motivate yourself. Knowing how far you’ve come will only push you further!</span></div><div id="i0doz"><div id="iobci">
         </div></div><div id="izmuadf" class="innercol"><i id="ia91u" class="fas fa-thumbs-up"><p id="ifxf1"><br data-mce-bogus="1" id="in1o4"/></p></i><h3 id="ieg35"><p>HOW TO GET STARTED ASAP.</p></h3><span id="imehl">It’s hard to find time for fitness between work and kids, but creating a routine will keep you on track, and you’ll see real results when you become consistent.</span></div></div><div id="iyd38"><div id="iyn4n"><p><br data-mce-bogus="1"/></p></div></div></div><div id="iqo3k" class="container"></div><div id="iyjli" class="container"><form id="ivamj"><div id="i5p7h5" class="form-group"><label id="i6qvb2" class="label"><p id="icsey"><br data-mce-bogus="1" id="i2g54"/></p></label><input type="email" placeholder="Enter your email address" id="iflgh" class="input"/></div><div id="irl87y" class="form-group"><button type="submit" class="button">CLICK HERE TO DOWNLOAD</button></div></form></div><div><div id="ipr6x"></div></div></div><div id="i5akgg"><div id="inzc3" class="container"><div id="ias8k" class="row"><div id="i1i5g9" class="cell"><img id="iy9h31" src="https://media.floatfunnels.com/image.php?page=kierans-demo/42492&imgfile=5cf50173-3658-a549-9810-816bab38761b.png"/></div></div><div id="i5oonf" class="row"><div id="ivn0lm" class="cell"><div id="i42hcv"><p>©2024 Your Company Name. All rights reserved.</p></div></div><div id="it67sv" class="cell"><a href="https://api.leadpages.io/template/v2/editions/yvcEsdQU4LUGzQqrJzAxHW/preview.html#" id="ik097p"></a><a id="ifxs4k" href="#"><p>Privacy Policy</p></a></div></div></div></div></body>`,
      pag_json: JSON.stringify(data),
      pag_title: "test grape",
    });

    client
      .request(options)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    // grapeEditor.loadProjectData(data);
    /*
    grapeEditor.Pages.getAll().map((page) => {
      const component = page.getMainComponent();
      console.log(grapeEditor.getHtml({ component }));
    });
    */
  };

  const onEditor = (editor) => {
    setGrapeEditor(editor);
    console.log("Editor loaded", { editor });
  };

  /*
  useEffect(() => {
    const edi = grapesjs.init({
      container: "#gjs",
    });
    setEditor(edi);
  }, []);
*/
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <button onClick={getCode}>Get Code</button>
      <GjsEditor
        // Pass the core GrapesJS library to the wrapper (required).
        // You can also pass the CDN url (eg. "https://unpkg.com/grapesjs")
        grapesjs={grapesjs}
        // Load the GrapesJS CSS file asynchronously from URL.
        // This is an optional prop, you can always import the CSS directly in your JS if you wish.
        grapesjsCss="https://unpkg.com/grapesjs/dist/css/grapes.min.css"
        // GrapesJS init options
        options={{
          height: "100vh",
          storageManager: false,
        }}
        plugins={[gjspresetwebpage, gjsblockbasic]}
        onEditor={onEditor}
      />
    </DashboardLayout>
  );
}
