import client from "ApiClient";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";

import { Grid, Icon, Tooltip } from "@mui/material";
import PageView from "./components/PageView";
import MDTypography from "components/MDTypography";
import moment from "moment";

/* eslint-disable */
export default function Pages() {
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);

  const navigate = useNavigate();

  const options = {
    method: "GET",
    url: "pages",
  };

  const getData = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `pages`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setItems(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = async (id) => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `pages?id=${id}`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const actionButtons = (id, version) => {
    return (
      <>
        <Tooltip title="Edit" placement="bottom">
          <MDTypography
            variant="body1"
            color="info"
            lineHeight={1}
            sx={{ cursor: "pointer", mx: 3 }}
          >
            <Icon
              color="inherit"
              onClick={() => {
                version == 1 ? navigate(`/pages/addV2?id=${id}`) : navigate(`/pages/add?id=${id}`);
                window.location.reload();
              }}
            >
              edit
            </Icon>
          </MDTypography>
        </Tooltip>
        <Tooltip title="Delete" placement="bottom">
          <MDTypography
            variant="body1"
            color="info"
            lineHeight={1}
            sx={{ cursor: "pointer", mx: 3 }}
          >
            <Icon color="secondary" onClick={() => handleDelete(id)}>
              delete
            </Icon>
          </MDTypography>
        </Tooltip>
      </>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={10}>
          <MDButton variant="gradient" color="primary" onClick={() => navigate("/pages/add")}>
            new page
          </MDButton>
          {/*}
          <MDBox display="flex">
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
          </MDBox>
          */}
        </MDBox>
        <Grid container spacing={10}>
          {items.map((item) => {
            return (
              <Grid item xs={12} sm={6} md={4} xl={4}>
                <PageView
                  title={item.pag_title}
                  date={moment(item.pag_creationDate).format("DD/MM/YYYY")}
                  url={item.pag_url}
                  image={`https://thumbs.funnelsense.ai/${item.pag_id}.png`} // {item.pag_url}
                  action={actionButtons(item.pag_id, item.pag_version)}
                  status={item.pag_status}
                />
              </Grid>
            );
          })}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
